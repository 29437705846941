import Beginner from "@/assets/media/svg/Beginner-level-badge.svg"
import BeginnerTest from "@/assets/media/svg/Beginner-test-level-badge.svg"
import Expert from "@/assets/media/svg/Expert-level-badge.svg"
import ExpertTest from "@/assets/media/svg/Expert-test-level-badge.svg"
import Intermediate from "@/assets/media/svg/Intermediate-level-badge.svg"
import NotApplicable from "@/assets/media/svg/Not-applicable-level-badge.svg"
import NotApplicableTest from "@/assets/media/svg/Not-applicable-test-level-badge.svg"
import AudioQuestion from "@/assets/media/svg/audio-test-icon-cart.svg"
import CodingTest from "@/assets/media/svg/coding-icon-cart.svg"
import CognitiveTest from "@/assets/media/svg/cognitive-test-icon.svg"
import ChatIcon from "@/assets/media/svg/create-assessment-icons/chatsimulator.svg"
import DROPDOWN from "@/assets/media/svg/create-assessment-icons/dropdown-question.svg"
import FillInBlank from "@/assets/media/svg/create-assessment-icons/fill-the-blank.svg"
import NumberQues from "@/assets/media/svg/create-assessment-icons/number-question.svg"
import RANKING from "@/assets/media/svg/create-assessment-icons/ranking-question.svg"
import RATING from "@/assets/media/svg/create-assessment-icons/rating-question.svg"
import ShortAnswer from "@/assets/media/svg/create-assessment-icons/short-answer.svg"
import VoiceAI from "@/assets/media/svg/create-assessment-icons/voice-ai.svg"
import DateAnswer from "@/assets/media/svg/date-option-icon.svg"
import DocumentQuestion from "@/assets/media/svg/document-cart.svg"
import EngineeringSkills from "@/assets/media/svg/engineering-type-icon.svg"
import FileUpload from "@/assets/media/svg/file-upload-icon-big-cart.svg"
import Language from "@/assets/media/svg/language-type-icon.svg"
import MultiSelect from "@/assets/media/svg/multiple-select-question-cart.svg"
import WrittenAnswer from "@/assets/media/svg/open-ended-question-cart.svg"
import ProgrammingSkills from "@/assets/media/svg/programming-type-icon.svg"
import Psychometric from "@/assets/media/svg/psychometric-test-type.svg"
import QualifyingQuestion from "@/assets/media/svg/qualifier-question-cart.svg"
import qualifyingQuestion from "@/assets/media/svg/qualifier-question.svg"
import VideoAnswer from "@/assets/media/svg/record-video-question-cart.svg"
import RoleSpecificSkills from "@/assets/media/svg/role-specific-icon.svg"
import SingleSelect from "@/assets/media/svg/single-select-question-cart.svg"
import SituationalJudgement from "@/assets/media/svg/situational-judgement-icon.svg"
import Slides from "@/assets/media/svg/slides-cart.svg"
import SoftwareSkills from "@/assets/media/svg/software-skills-icon.svg"
import SpreadSheet from "@/assets/media/svg/spreadsheet-cart.svg"
import TypingTest from "@/assets/media/svg/typing-cart.svg"
import ChoiceQuestion from "@/assets/media/svg/yes-no-option-icon.svg"
import {
    PERSONAL_EMAIL_DOMAINS,
    PRIVACY_POLICY_URL,
    PSYCHOMETRIC_TEST_LIBRARY_TYPE_ID,
    S3DOMAINS,
    SUPPORT_EMAIL,
    TERMS_CONDITION_URL,
} from "@/core/constants"

import useApiAssessment from "@/services/apiAssessment"
import colorLib from "@kurkle/color"
import algoliasearch from "algoliasearch/lite"
import { CountryCode } from "libphonenumber-js"
import countryMetadata from "libphonenumber-js/metadata.min.json"
import "moment/min/locales"
import moment from "moment/moment"
import { useRouter } from "vue-router"
import i18n from "../plugins/i18n"
import { store } from "../store"
import {
    ALGOLIA_APP_ID,
    ALGOLIA_READ_KEY,
} from "../view/pages/general/search/constants"

export default function helperFunction() {
    //convert hh:mm:ss to seconds
    const convertTimeToSeconds = (time: any) => {
        const timeArray = time.split(":")
        const hours = parseInt(timeArray[0])
        const minutes = parseInt(timeArray[1])
        const seconds = parseInt(timeArray[2])
        return hours * 3600 + minutes * 60 + seconds
    }
    function formatNumber(value: number | string): string {
        return Number(value).toLocaleString()
    }
    const mimeTypes = {
        pdf: "application/pdf",
        jpg: "image/jpeg",
        txt: "text/plain",
    }
    const convertMinutesToSeconds = (minutes: any) => {
        return minutes * 60
    }
    const whiteLabeledPlan = [
        "white-labeled-usd-yearly",
        "white-labeled-inr-yearly",
        "white-labeled-gbp-yearly",
        "white-labeled-inr-monthly",
        "white-labeled-usd-monthly",
        "white-labeled-INR-Monthly",
        "white-labeled-gbp-monthly",
    ]
    const basicPlan: string[] = [
        "grow-inr-yearly",
        "grow-INR-Yearly",
        "grow-INR-Monthly",
        "grow-USD-Yearly",
        "grow-usd-yearly",
        "grow-USD-Monthly",
        "grow-GBP-Yearly",
        "grow-GBP-Monthly",
    ]

    //convert Seconds value to minutes and seconds in min:sec format
    const formatTime = (s: number) => {
        return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s.toFixed()
    }

    //convert Seconds value to hours,minutes and seconds in 1hr:2min:3sec format
    const toHMSTime = (d: number) => {
        d = Number(d)
        const h = Math.floor(d / 3600)
        const m = Math.floor((d % 3600) / 60)
        const s = Math.floor((d % 3600) % 60)

        let hDisplay = h > 0 ? h + "h" : ""
        let mDisplay = m > 0 ? m + "m" : ""
        let sDisplay = s > 0 ? s + "s" : ""

        let timeString = ""

        if (h > 0 && m > 0 && s > 0) {
            timeString = hDisplay + " " + mDisplay + " " + sDisplay
        } else if (h > 0 && m > 0 && s <= 0) {
            timeString = hDisplay + " " + mDisplay
        } else if (h <= 0 && m > 0 && s > 0) {
            timeString = mDisplay + " " + sDisplay
        } else if (h > 0 && m <= 0 && s <= 0) {
            timeString = hDisplay
        } else if (h <= 0 && m > 0 && s <= 0) {
            timeString = mDisplay
        } else if (h <= 0 && m <= 0 && s > 0) {
            timeString = sDisplay
        }

        return timeString
    }

    const timeValueMin = (value: number) => {
        return value > 60 ? "constants_text.minutes" : "constants_text.minute"
    }

    const convertSecToMin = (value: number) => {
        if (!value) {
            return 0
        }
        const minutes = value / 60
        const formattedMinutes =
            minutes % 1 === 0 ? minutes.toFixed(0) : minutes.toFixed(2)
        return `${formattedMinutes}`
    }

    const roleNamesFormatting = (value: string) => {
        if (value === "ADMIN") {
            return "Admin"
        } else if (value === "OWNER") {
            return "Owner"
        } else if (value === "MEMBER") {
            return "Member"
        }
        return value
    }
    const initialCapitalize = (value: string) => {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        } else {
            return ""
        }
    }
    const initialLowerCase = (value: string) => {
        if (value) {
            return value.toLowerCase()
        } else {
            return ""
        }
    }

    // TO_BE_EVALUATED ==> To Be Evaluated
    const snakeCaseToTitleCase = (text: string) => {
        if (!text) return ""
        return text
            .split("_")
            .map((word) =>
                word.includes("AI") ? word : initialCapitalize(word)
            )
            .join(" ")
    }

    // To Be Evaluated ==> TO_BE_EVALUATED
    const titleCaseToSnakeCase = (text: string) => {
        if (!text) return ""
        return text
            .split(" ")
            .map((t: string) => t.toUpperCase())
            .join("_")
    }

    const convertedDate = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""
        let newDate = moment(theDate).format()
        newDate = moment(newDate).format("LL")
        return newDate
    }

    const convertedDateTime = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""
        let newDate = moment(theDate).format()
        newDate = moment(newDate).format("LLL")
        return newDate
    }

    const invitedDateTime = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""
        let apiDate = moment(theDate)
        let todayDate = moment()
        let yesterdayDate = moment().add(-1, "days")
        return moment(apiDate).format("MMM D, h:mm A")
    }
    const assessmentDeadlineDateTime = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""
        let apiDate = moment(theDate)
        return moment(apiDate).format("MMM DD, YYYY hh:mm A")
    }
    const formatStatusDateTime = (
        theDate: moment.MomentInput | null,
        locale: string
    ) => {
        if (!theDate) return ""
        moment.locale(locale)
        const apiDate = moment(theDate)
        const dateFormat = "MMM D, YYYY h:mm A"
        return moment(apiDate).format(dateFormat)
    }
    const formatDate = (theDate: string) => {
        if (!theDate) return ""
        const apiDate = moment(theDate).format("MMM DD, YYYY")
        return apiDate
    }

    const formatToReadableDate = (theDate: string) => {
        if (!theDate) return ""
        const apiDate = moment(theDate, "DD-MM-YYYY").format("MMM DD, YYYY")
        return apiDate
    }

    function isGoogleFile(url: string): boolean {
        const googlePattern =
            /https:\/\/docs\.google\.com\/(document|spreadsheets|presentation|forms|drawings)\/d\//i
        return googlePattern.test(url)
    }

    const downloadFileDirectly = async (url: string) => {
        try {
            const response = await fetch(url, { mode: "cors" })
            if (!response.ok) throw new Error("Network response was not ok")

            const blob = await response.blob()
            const blobUrl = window.URL.createObjectURL(blob)

            const link = document.createElement("a")
            link.href = blobUrl
            link.download = url.split("/").pop() || "download"
            document.body.appendChild(link)
            link.click()

            // Cleanup
            document.body.removeChild(link)
            window.URL.revokeObjectURL(blobUrl)
        } catch (error) {
            console.error("Download failed")
        }
    }

    function downloadGoogleFile(url: string): void {
        if (!isGoogleFile(url)) {
            console.log("Not a Google file - using direct download")
            const link = document.createElement("a")
            link.href = url
            link.download = url.split("/").pop() || "download"
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            return
        }

        try {
            const fileId = url.match(/\/d\/([^\/]+)\//)?.[1]
            if (!fileId) throw new Error("Invalid Google file URL")

            const { exportUrl, extension } = getGoogleExportSettings(
                url,
                fileId
            )

            const link = document.createElement("a")
            link.href = exportUrl
            link.download = `file.${extension}`
            link.style.display = "none"
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            console.error("Google download failed:")
            window.open(url, "_blank")
        }
    }

    function getGoogleExportSettings(
        url: string,
        fileId: string
    ): { exportUrl: string; extension: string } {
        if (url.includes("/document/")) {
            return {
                exportUrl: `https://docs.google.com/document/d/${fileId}/export?format=pdf`,
                extension: "pdf",
            }
        }

        if (url.includes("/spreadsheets/")) {
            return {
                exportUrl: `https://docs.google.com/spreadsheets/d/${fileId}/export?format=xlsx`,
                extension: "xlsx",
            }
        }

        if (url.includes("/presentation/")) {
            return {
                exportUrl: `https://docs.google.com/presentation/d/${fileId}/export/pptx`,
                extension: "pptx",
            }
        }

        throw new Error("Unsupported Google file type")
    }

    const downloadURI = (uri: string) => {
        let link = document.createElement("a")
        link.href = uri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    // set the cookie named "sessionAccessKeyCookie" as default value
    // use cookieName to set anything else in cookie(Currently I have used this function to set workspaceUrl)
    const setSessionCookie = (
        sessionKey: string,
        cookieName = "sessionAccessKeyCookie"
    ) => {
        const daysToExpire = new Date(
            Date.now() + 2147483647 * 1000
        ).toUTCString()
        const domain = location.hostname.split(".").splice(-2).join(".")
        if (window.location.href.includes("/localhost")) {
            document.cookie = `${cookieName}=${sessionKey}; path=/; expires=${daysToExpire}`
        } else if (window.location.href.includes("vercel.app")) {
            document.cookie = `${cookieName}=${sessionKey}; domain=.${window.location.host}; path=/; expires=${daysToExpire}`
        } else {
            document.cookie = `${cookieName}=${sessionKey}; domain=.${domain}; path=/; expires=${daysToExpire}`
        }
    }

    // get the value of the cookie named "sessionAccessKeyCookie" as default value
    // you can also get other cookies like (workspaceUrl)
    const getSessionCookie = (cookieName = "sessionAccessKeyCookie") => {
        let name = `${cookieName}=`
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == " ") {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ""
    }

    //percent value should be negative for darker shade , positive for lighter shade
    function getShadeColor(color: string, percent: number) {
        let R = parseInt(color.substring(1, 3), 16)
        let G = parseInt(color.substring(3, 5), 16)
        let B = parseInt(color.substring(5, 7), 16)
        // @ts-ignore
        R = parseInt((R * (100 + percent)) / 100)
        // @ts-ignore
        G = parseInt((G * (100 + percent)) / 100)
        // @ts-ignore
        B = parseInt((B * (100 + percent)) / 100)

        R = R < 255 ? R : 255
        G = G < 255 ? G : 255
        B = B < 255 ? B : 255

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        let RR =
            R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16)
        let GG =
            G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16)
        let BB =
            B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16)

        return "#" + RR + GG + BB
    }

    // get the value of the cookie named related to "UTM"
    const getUTMCookies = (value: any) => {
        let name = value + "="
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == " ") {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ""
    }

    const showImagePreview = () => {
        let clickCount = 0
        let timer

        document.addEventListener(
            "click",
            function (e: any) {
                if (
                    e.target.tagName === "IMG" &&
                    S3DOMAINS.some((pattern) =>
                        e.target.src.includes(pattern)
                    ) &&
                    e.target.src.includes("question") &&
                    !e.target.className.includes("fslightboxs")
                ) {
                    clickCount++
                    if (clickCount === 1) {
                        timer = setTimeout(function () {
                            let lightbox = new window.FsLightbox()
                            lightbox.props.sources = [e.target.src]
                            lightbox.props.types = ["image"]
                            lightbox.open()
                            clickCount = 0
                        }, 200)
                    } else {
                        clearTimeout(timer)
                        clickCount = 0
                    }
                }
            },
            false
        )

        document.addEventListener(
            "dblclick",
            function () {
                clearTimeout(timer)
                clickCount = 0
            },
            false
        )
    }

    // Function to get country from phone extension
    const getCountryFromExt = (ext: string): string => {
        try {
            const cleanExt = ext.replace("+", "")
            const countries = countryMetadata.country_calling_codes[
                cleanExt
            ] as CountryCode[]
            return countries?.[0] || "IN"
        } catch (error) {
            return "IN"
        }
    }

    // delete the cookie named "sessionAccessKeyCookie"
    const deleteSessionCookie = (cookieName = "sessionAccessKeyCookie") => {
        const domain = location.hostname.split(".").splice(-2).join(".")
        if (window.location.href.includes("/localhost")) {
            document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        } else if (window.location.href.includes("vercel.app")) {
            document.cookie = `${cookieName}=; domain=.${window.location.host}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        } else {
            document.cookie = `${cookieName}=; domain=.${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        }
    }

    function saveAdTrackingParams() {
        const urlParams = new URLSearchParams(window.location.search)

        const trackingParams: Record<string, string> = {
            gclid: "google",
            li_fat_id: "linkedin",
            fbclid: "facebook",
            rdt_cid: "reddit",
        }

        for (const param in trackingParams) {
            const value = urlParams.get(param)
            if (value) {
                localStorage.setItem(
                    "ad_tracking",
                    JSON.stringify({
                        id: value,
                        utm_source: trackingParams[param],
                    })
                )
                break
            }
        }
    }

    const loadScript = (
        url: string,
        async: boolean = false,
        defer: boolean = false
    ) => {
        return new Promise((resolve) => {
            let head = document.querySelectorAll("head")[0]
            let script = document.createElement("script")

            script.type = "text/javascript"
            script.async = async
            script.defer = defer
            script.addEventListener("load", () => {
                resolve(script)
            })

            script.src = url
            head.append(script)
        })
    }
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_READ_KEY)

    const randomStringGenerator = (len) => {
        let text = ""

        let charset = "abcdefghijklmnopqrstuvwxyz0123456789"
        //bearer:disable javascript_lang_insufficiently_random_values
        for (let i = 0; i < len; i++)
            text += charset.charAt(Math.floor(Math.random() * charset.length))

        return text
    }
    const setFavicon = (faviconUrl: string) => {
        let link: any =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link")
        link.type = "image/x-icon"
        link.rel = "shortcut icon"
        link.href = faviconUrl
        document.getElementsByTagName("head")[0].appendChild(link)
    }
    const getDurationValues = (durationList: any) => {
        return durationList
            .map((item) => item.value)
            .filter((item) => item !== 1)
    }
    function getCompanyName(email) {
        const atIndex = email.indexOf("@")
        if (atIndex === -1) return null // no '@' symbol found

        const domain = email.substring(atIndex + 1) // get the domain name
        const dotIndex = domain.indexOf(".")
        if (dotIndex === -1) return null // invalid domain name

        return domain.substring(0, dotIndex)
    }
    function getCurrencyCode() {
        const dateString = new Date().toString()
        return dateString.includes("India")
            ? "INR"
            : dateString.includes("British")
            ? "GBP"
            : "USD"
    }

    function isTestlifyDomain() {
        return [
            "app.testlify.com",
            "testlify-six",
            "localhost",
            "testlify-git-",
        ].some((href) => window.location.href.includes(href))
    }

    function getTermsAndPrivacyUrl(): { termsUrl: string; privacyUrl: string } {
        const isReseller = store.getters.getResellerStatus
        const isWhiteLabel = store.getters.getWhiteLabelStatus
        const termsUrl = store.getters.getTermsUrl
        const privacyUrl = store.getters.getPrivacyUrl

        if (!isTestlifyDomain() && isReseller && !isWhiteLabel) {
            return {
                termsUrl: TERMS_CONDITION_URL,
                privacyUrl: PRIVACY_POLICY_URL,
            }
        }
        return { termsUrl, privacyUrl }
    }

    function getPreviewDomain() {
        const host = window.location.host
        const domainMap = {
            "localhost:8080": "localhost:3000",
            "assessments.join-staging.com":
                "https://candidates.join-staging.com",
            "assessments.join.com": "https://candidates.join.com",
        }

        const defaultDomain = "https://candidate.testlify.com"
        const resellerDomain = store.getters.getResellerDomain

        // Determine previewDomain based on host or reseller domain
        const previewDomain =
            domainMap[host] ||
            (resellerDomain ? `https://${resellerDomain}` : defaultDomain)

        return previewDomain
    }

    const isNumberWithMaxDigit = (evt: KeyboardEvent) => {
        const keysAllowed: string[] = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
        ]
        const keyPressed: string = evt.key

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault()
        }
        const maxLimit = 3
        const inputElement = evt.target as HTMLInputElement
        if (inputElement.value.length >= maxLimit) {
            evt.preventDefault()
        }
    }

    const isValidNumericInput = (evt: KeyboardEvent) => {
        const keysAllowed: string[] = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            ".",
        ]

        const keyPressed: string = evt.key
        const inputElement = evt.target as HTMLInputElement
        const currentValue = inputElement.value

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault()
            return
        }

        if (
            keyPressed === "-" &&
            (currentValue.includes("-") || currentValue.length > 0)
        ) {
            evt.preventDefault()
            return
        }

        if (keyPressed === "." && currentValue.includes(".")) {
            evt.preventDefault()
            return
        }

        if (keyPressed === "." && currentValue === "-") {
            evt.preventDefault()
            return
        }
        const maxLimit = 5
        if (inputElement.value.length >= maxLimit) {
            evt.preventDefault()
        }
    }

    const isNumberAllowed = (evt: KeyboardEvent) => {
        const keysAllowed: string[] = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            ".",
        ]
        const keyPressed: string = evt.key

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault()
        }
    }
    const onlyNumbersAllowed = (evt: KeyboardEvent) => {
        const keysAllowed: string[] = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
        ]
        const keyPressed: string = evt.key

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault()
        }
    }
    const router = useRouter()
    const updateQueryParameter = (param, value) => {
        const query = { ...router.currentRoute.value.query }
        if (value) {
            query[param] = value
        } else {
            delete query[param]
        }
        router.replace({
            query: query,
        })
    }
    function getColorByBgColor(bgColor) {
        let color =
            bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
        let r = parseInt(color.substring(0, 2), 16) // hexToR
        let g = parseInt(color.substring(2, 4), 16) // hexToG
        let b = parseInt(color.substring(4, 6), 16) // hexToB
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff"
    }
    const transparentise = (value, opacity) => {
        let alpha = opacity === undefined ? 0.5 : 1 - opacity
        return colorLib(value).alpha(alpha).rgbString()
    }

    const getJwtExpiration = (jwtToken) => {
        const [, payloadBase64] = jwtToken.split(".")
        const payloadJson = atob(payloadBase64)
        const payload = JSON.parse(payloadJson)
        const expirationTime = payload.exp
        return expirationTime
    }

    function numberInRange(num, low, high) {
        if (num >= low && num <= high) {
            return true
        }
        return false
    }

    function checkResellerSupport(email: string) {
        return (
            email === "joseph.abourous@ampowered.me" ||
            email === "bharat@exceedxe.com" ||
            email === "info@balticresourcing.com" ||
            SUPPORT_EMAIL === email
        )
    }

    function getMainDomain(inputDomain) {
        // Split the input domain into parts
        let domainParts = inputDomain.split(".")

        // Check if the domain has at least two parts
        if (domainParts.length >= 2) {
            // If the last part is a common top-level domain (TLD), exclude it
            if (domainParts[domainParts.length - 1].length <= 3) {
                return domainParts[domainParts.length - 2]
            } else {
                return (
                    domainParts[domainParts.length - 2] +
                    "." +
                    domainParts[domainParts.length - 1]
                )
            }
        } else {
            // If the domain has less than two parts, return the original input
            return inputDomain
        }
    }

    // Check if question has non empty options
    const hasNonEmptyOption = (questionOptions) => {
        for (let i = 0; i < questionOptions?.length; i++) {
            if (questionOptions[i]?.description?.length > 0) {
                return true
            }
        }
        return false
    }

    //To concatenate the question options
    const concatenateQuestionOptions = (optionList) => {
        let tempStr = ""
        optionList.map(
            (data) =>
                (tempStr += data.description + data.isCorrect + data.score)
        )
        return tempStr
    }
    const concatenateQuestionOptionsFIB = (optionlist) => {
        let temp = ""
        optionlist.forEach((item) => {
            if (item.options) {
                item.options.forEach((option) => {
                    temp += option.description + option.isCorrect + option.score
                })
            }
        })
        return temp
    }
    const concatenateTestCases = (optionlist) => {
        let temp = ""
        optionlist.forEach((item) => {
            temp += item.input + item.output + item.isHidden
        })
        return temp
    }

    // Extracts the typing test duration from the given object or returns the duration from the 'duration' property.
    function typingTestDuration(val: any) {
        const item = val?.answer?.[0]
        const duration = item?.split(",")?.[3]?.split(":")?.[1]?.trim()
        return duration ?? val?.elapseDuration?.toFixed() ?? null
    }
    function translateIndustry(industry) {
        switch (industry) {
            case "Accountancy, banking and finance":
                return i18n.global.t(
                    "industry_translation.accountancy_banking_finance"
                )
            case "Agriculture":
                return i18n.global.t("industry_translation.agriculture")
            case "Applicable across all industries":
                return i18n.global.t(
                    "industry_translation.applicable_across_all_industries"
                )
            case "Construction":
                return i18n.global.t("industry_translation.construction")
            case "Corporate Services":
                return i18n.global.t("industry_translation.corporate_services")
            case "Design":
                return i18n.global.t("industry_translation.design")
            case "Education":
                return i18n.global.t("industry_translation.education")
            case "Energy & Mining":
                return i18n.global.t("industry_translation.energy_mining")
            case "Entertainment":
                return i18n.global.t("industry_translation.entertainment")
            case "Hardware & Networking":
                return i18n.global.t("industry_translation.hardware_networking")
            case "Health Care":
                return i18n.global.t("industry_translation.health_care")
            case "Legal":
                return i18n.global.t("industry_translation.legal")
            case "Manufacturing":
                return i18n.global.t("industry_translation.manufacturing")
            case "Media & Communications":
                return i18n.global.t(
                    "industry_translation.media_communications"
                )
            case "Public Administration":
                return i18n.global.t(
                    "industry_translation.public_administration"
                )
            case "Public Safety":
                return i18n.global.t("industry_translation.public_safety")
            case "Real Estate":
                return i18n.global.t("industry_translation.real_estate")
            case "Recreation, Travel & Hospitality":
                return i18n.global.t(
                    "industry_translation.recreation_travel_hospitality"
                )
            case "Sales & Retail":
                return i18n.global.t("industry_translation.sales_retail")
            case "Software & IT Services":
                return i18n.global.t(
                    "industry_translation.software_it_services"
                )
            case "Transportation & Logistics":
                return i18n.global.t(
                    "industry_translation.transportation_logistics"
                )
            case "Wellness & Fitness":
                return i18n.global.t("industry_translation.wellness_fitness")
            case "Humanitarian Sector":
                return i18n.global.t("industry_translation.humanitarian_sector")
            default:
                return industry
        }
    }

    const getTestTypeIcon = (val: string) => {
        switch (val) {
            case "Cognitive ability":
                return CognitiveTest
            case "Role specific skills":
                return RoleSpecificSkills
            case "Programming skills":
                return ProgrammingSkills
            case "Coding test":
                return CodingTest
            case "Language":
                return Language
            case "Psychometric":
                return Psychometric
            case "Engineering skills":
                return EngineeringSkills
            case "Situational judgment":
                return SituationalJudgement
            case "Situational Judgement":
                return SituationalJudgement
            case "Software skills":
                return SoftwareSkills
            case "WRITTEN_ANSWER":
                return WrittenAnswer
            case "AUDIO_ANSWER":
                return AudioQuestion
            case "VIDEO_ANSWER":
                return VideoAnswer
            case "SINGLE_SELECT":
                return SingleSelect
            case "MULTI_SELECT":
                return MultiSelect
            case "TYPING_TEST":
                return TypingTest
            case "SLIDES":
                return Slides
            case "SPREADSHEET":
                return SpreadSheet
            case "MS_EXCEL":
                return SpreadSheet
            case "DOCUMENT":
                return DocumentQuestion
            case "FILE_UPLOAD":
                return FileUpload
            case "QUALIFYING":
                return qualifyingQuestion
            case "CHOICE_ANSWER":
                return ChoiceQuestion
            case "SHORT_ANSWER":
                return ShortAnswer
            case "DATE_ANSWER":
                return DateAnswer
            case "NUMBER":
                return NumberQues
            case "QUALIFYING_QUESTION":
                return QualifyingQuestion
            case "FILL_IN_BLANK":
                return FillInBlank
            case "RANKING":
                return RANKING
            case "DROPDOWN":
                return DROPDOWN
            case "CODING_ANSWER":
                return CodingTest
            case "CHAT_SIMULATION":
                return ChatIcon
            case "RATING":
                return RATING
            case "VOICE_AI":
                return VoiceAI
            case "MS_POWERPOINT":
                return Slides
            case "MS_WORD":
                return DocumentQuestion
            default:
                return CognitiveTest
        }
    }
    // Question type list: Always add any new question type in ALPHABETICAL ORDER
    const questionTypeTranslationMapList: { [key: string]: string } = {
        AUDIO_ANSWER: "voice",
        CHAT_SIMULATION: "chat_simulation",
        CODING_ANSWER: "coding",
        DATE_ANSWER: "date_answer",
        DOCUMENT: "document",
        DROPDOWN: "dropdown",
        FILE_UPLOAD: "file_upload",
        FILL_IN_BLANK: "fill_in_blank",
        MS_EXCEL: "ms_excel",
        MS_POWERPOINT: "ms_powerpoint",
        MS_WORD: "ms_word",
        MULTI_SELECT: "multi_select",
        NUMBER: "number",
        WRITTEN_ANSWER:
            "long_answer" /* Added WRITTEN_ANSWER here to maintain sort order as it represents OPEN_ENDED */,
        PRACTICAL: "practical",
        RANKING: "ranking",
        RATING: "rating",
        SHORT_ANSWER: "short_answer",
        SINGLE_SELECT: "single_select",
        SLIDES: "slides",
        SPREADSHEET: "spreadsheet",
        TYPING_TEST: "typing_test",
        VIDEO_ANSWER: "video_record",
        VOICE_AI: "voice_ai",
        CHOICE_ANSWER:
            "choice_answer" /* Added CHOICE_ANSWER here to maintain sort order as it represents YES/NO */,
        VIDEO_AI: "video_ai",
    }

    // This code creates a list of question types with their corresponding translation keys for display purposes.
    const orderedQuestionTypeMap = () => {
        const questionTypeMap = Object.keys(questionTypeTranslationMapList).map(
            (key, index) => ({
                id: index + 1,
                value: key,
                name: i18n.global.t(
                    `constants_text.${questionTypeTranslationMapList[key]}` ||
                        "constants_text.video"
                ),
            })
        )
        return questionTypeMap
    }

    const checkLang = (language, langCode) => {
        let langObj = {
            English: "EN",
            Dutch: "NL",
            Deutsch: "DE",
            Arabic: "AR",
            Italian: "IT",
            French: "FR",
            Español: "ES",
            Japanese: "JA",
            Portuguese: "PT",
            Russian: "RU",
            Mandarin: "ZH",
            Chinese: "ZH",
            Korean: "KO",
            Hebrew: "HE",
            Turkish: "TR",
            Vietnamese: "VI",
            Greek: "EL",
            Swedish: "SV",
            Thai: "TH",
            Filipino: "TL",
            Hindi: "HI",
            Marathi: "MR",
            Malaysian: "MS",
            Norwegian: "NO",
            Polish: "PL",
            Indonesian: "IN",
            Romanian: "RO",
            Danish: "DA",
            German: "DE",
            Spanish: "ES",
        }
        const langId = langObj[language] ?? ""
        return langId.toLowerCase() !== langCode ? langId : ""
    }

    const translateDifficultyLevel = (difficultyLevel: string): string => {
        const levelMapping: { [key: string]: string } = {
            beginner: "constants_text.beginner",
            easy: "constants_text.beginner",
            intermediate: "constants_text.intermediate",
            medium: "constants_text.intermediate",
            hard: "constants_text.advanced",
            advanced: "constants_text.advanced",
            challenging: "constants_text.expert",
            expert: "constants_text.expert",
            not_applicable: "generic_test.not_applicable",
            "not applicable": "generic_test.not_applicable",
        }

        const normalizedLevel = difficultyLevel.toLowerCase()
        const translationKey = levelMapping[normalizedLevel] || ""

        return translationKey ? i18n.global.t(translationKey) : ""
    }

    const getScoreBadge = (score) => {
        if (score > 75) return "badge-purple"
        else if (score > 50 && score <= 75) return "badge-green"
        else if (score > 25 && score <= 50) return "badge-golden"
        else if (score >= 0 && score <= 25) return "badge-red"
        else return ""
    }
    const getDifficultyLevelIcon = (
        difficultyLevel: string,
        type: "question" | "test" = "question"
    ) => {
        switch (difficultyLevel) {
            case "beginner":
                return type === "question" ? Beginner : BeginnerTest
            case "intermediate":
                return Intermediate
            case "advanced":
                return Expert
            case "expert":
                return type === "question" ? Expert : ExpertTest
            case "easy":
                return type === "question" ? Beginner : BeginnerTest
            case "challenging":
                return type === "question" ? Expert : ExpertTest
            case "not_applicable":
                return type === "question" ? NotApplicable : NotApplicableTest
            case "not applicable":
                return type === "question" ? NotApplicable : NotApplicableTest
            case "hard":
                return Expert
            case "medium":
                return Intermediate
            default:
                return ""
        }
    }
    function getDaysSinceCompletion(completedAt: string) {
        const today = new Date()
        const completedDate = new Date(completedAt)
        return Math.floor(
            (today.getTime() - completedDate.getTime()) / (1000 * 60 * 60 * 24)
        )
    }
    function getAutoDeleteMessage(completedAt: string, classification: string) {
        const today = new Date()
        const completedDate = new Date(completedAt)
        const diffInDays = Math.floor(
            (today.getTime() - completedDate.getTime()) / (1000 * 60 * 60 * 24)
        )
        if (diffInDays === 88) {
            if (classification === "VIDEO_ANSWER") {
                return (
                    i18n.global.t(
                        "custom_que.video_expired.delete_message_two_days"
                    ) +
                    ` <a style="color: #3f4254; text-decoration: underline; cursor: pointer" :href="VIDEO_DELETED_AFTER_90_DAYS_LINK" target="_blank">${i18n.global.t(
                        "custom_que.video_expired.learn_more"
                    )}</a>`
                )
            } else {
                return (
                    i18n.global.t(
                        "custom_que.audio_expired.delete_message_two_days"
                    ) +
                    ` <a style="color: #3f4254; text-decoration: underline;cursor: pointer" :href="VIDEO_DELETED_AFTER_90_DAYS_LINK" target="_blank">${i18n.global.t(
                        "custom_que.audio_expired.learn_more"
                    )}</a>`
                )
            }
        } else if (diffInDays === 89) {
            if (classification === "VIDEO_ANSWER") {
                return (
                    i18n.global.t(
                        "custom_que.video_expired.delete_message_one_day"
                    ) +
                    ` <a style="color: #3f4254; text-decoration: underline;cursor: pointer" :href="VIDEO_DELETED_AFTER_90_DAYS_LINK" target="_blank">${i18n.global.t(
                        "custom_que.video_expired.learn_more"
                    )}</a>`
                )
            } else {
                return (
                    i18n.global.t(
                        "custom_que.audio_expired.delete_message_one_day"
                    ) +
                    ` <a style="color: #3f4254; text-decoration: underline;cursor: pointer" :href="VIDEO_DELETED_AFTER_90_DAYS_LINK" target="_blank">${i18n.global.t(
                        "custom_que.audio_expired.learn_more"
                    )}</a>`
                )
            }
        }
        return null
    }
    const isVideoAudioExpired = (completedAt: string | null) => {
        if (!completedAt) return false

        const ninetyDays = 90 * 24 * 60 * 60 * 1000
        const completedDate = new Date(completedAt).getTime()
        const currentDate = new Date().getTime()
        return currentDate - completedDate > ninetyDays
    }
    const isPersonalEmail = (email: string): boolean => {
        if (!email.includes("@")) return false
        const domain = email.split("@")[1]
        return PERSONAL_EMAIL_DOMAINS.includes(domain)
    }

    const resellerStagingHosts = ["assessments.join-staging.com"]

    const isStaging =
        window.location.href.includes("localhost") ||
        window.location.href.includes("vercel") ||
        resellerStagingHosts.includes(window.location.host)
    function setCanonical(url: string) {
        let link = document.querySelector('link[rel="canonical"]')

        if (link) {
            link.setAttribute("href", url)
        } else {
            link = document.createElement("link")
            link.setAttribute("rel", "canonical")
            link.setAttribute("href", url)
            document.head.appendChild(link)
        }
    }

    const getQuestionCountFromLevelData = (questionnairePickConfig, level) => {
        return questionnairePickConfig
            .find((quest) => quest.level === +level)
            ?.config.reduce((sum, item) => sum + item.questionCount, 0)
    }
    const trimAndAddEllipsis = (array) => {
        return array?.map((obj) => {
            if (typeof obj.title === "string" && obj.title.length > 35) {
                obj.title = obj.title.substring(0, 30) + "..."
            }
            return obj
        })
    }

    const getTestLibrariesList = async (lang, search: string = "") => {
        const { getTestLibrariesId } = useApiAssessment()
        try {
            const response = await getTestLibrariesId(lang, search)
            if (response === -1) {
                return
            }
            return response.data
        } catch (e) {
            //bearer:disable javascript_lang_logger_leak
            console.log(e)
        }
    }
    const domainRestriction = (
        email: string,
        restrictionType: string,
        restrictedDomains: string[]
    ): boolean => {
        const emailDomain = email.split("@")[1]
        if (emailDomain === "testlify.com" || emailDomain === "hnrtech.com") {
            return true
        }
        if (!restrictedDomains.length) return true
        const isRestricted = restrictedDomains.includes(emailDomain)
        return restrictionType === "ALLOWED" ? isRestricted : !isRestricted
    }

    const filterLanguage = (listOfLang) => {
        const langs = listOfLang.map((lang) => {
            if (lang.code === "es") {
                lang.language = "Spanish"
            }
            return lang
        })
        return langs
    }

    const generateSlug = (title: string) => {
        return title
            .toString() // Ensure it's a string
            .toLowerCase() // Convert to lowercase
            .trim() // Remove leading and trailing spaces
            .replace(/[^\w\s-]/g, "") // Remove special characters except words, spaces, and hyphens
            .replace(/\s+/g, "-") // Replace spaces with hyphens
            .replace(/-+/g, "-") // Remove consecutive hyphens
    }

    const getMimeType = (url) => {
        if (!url) {
            return ""
        }
        const extension = url?.split(".").pop().split(/\#|\?/)[0]
        return mimeTypes[extension] || "application/octet-stream"
    }
    const getUserCountByPlanName = (planName: string) => {
        const planUserCounts: Record<string, number> = {
            startup: 3,
            grow: 5,
            business: 10,
            "white-labeled": 20,
        }

        return (
            planUserCounts[
                Object.keys(planUserCounts).find((key) =>
                    planName?.startsWith(key)
                ) || ""
            ] ?? 5
        )
    }

    const formatPercentage = (value: any) => {
        // Convert the value to a number and check if it's an integer
        const number = parseFloat(value)
        return Number.isInteger(number) ? `${number}%` : `${value}%`
    }

    const isOptionRepeated = (description, optionList) => {
        const descriptions = optionList
            .map((o) =>
                o.description
                    .trim()
                    .toLowerCase()
                    .replace(/[.,;:'"!?\s]+$/, "")
            )
            .filter((desc) => desc !== "")
        const occurrences = descriptions.filter(
            (desc) =>
                desc ===
                description
                    .trim()
                    .toLowerCase()
                    .replace(/[.,;:'"!?\s]+$/, "")
        )
        return occurrences.length > 1
    }
    function markdownToPlainText(markdown: string): string {
        return markdown
            .replace(/\*\*(.*?)\*\*/g, "$1")
            .replace(/\*(.*?)\*/g, "$1")
            .replace(/^\s*[-*]\s+/gm, "")
            .replace(/`(.*?)`/g, "$1")
            .replace(/#+\s*(.*)/g, "$1")
            .replace(/\n{2,}/g, "\n")
            .trim()
    }

    const formatDecimalNumber = (num) => {
        if (typeof num !== "number" || isNaN(num)) {
            return 0 // Return 0 for invalid input
        }
        return parseFloat(num.toFixed(2)) // No need for * 1
    }

    const checkValidation = ({ mode, isMin, isMax }, ranges) => {
        if (!ranges || ranges.length === 0) {
            return i18n.global.t(
                "assessment_setting_grading.error_message.empty_records"
            )
        }
        // Sort ranges by min value to ensure they are in order
        ranges.sort((a: any, b: any) => a.min - b.min)

        let missingRanges = []

        for (let i = 0; i < ranges.length - 1; i++) {
            const current = ranges[i]
            const next = ranges[i + 1]

            // Check if current max meets next min
            if (
                parseFloat(current.max.toFixed(2)) !==
                parseFloat((next.min - 0.01).toFixed(2))
            ) {
                // Push the missing range
                missingRanges.push(
                    `${(current.max + 0.01).toFixed(2)}-${(
                        next.min - 0.01
                    ).toFixed(2)}`
                )
            }
        }
        if (missingRanges.length > 0) {
            const result = fillMissingRanges(
                { mode: mode, isMin: isMin, isMax: isMax },
                ranges
            )
            return result.filledMissingRanges
                ? { item: result, msg: `List updated` }
                : ""
        } else return ""
    }

    // Method to auto update the assesment grading if any missing range is detected
    const fillMissingRanges = ({ mode, isMin, isMax }, grading) => {
        let filledMissingRanges = false
        const EPSILON = 0.01

        grading.sort((a, b) => a.min - b.min)

        if (grading[0].min !== 0) {
            grading[0].min = 0
            filledMissingRanges = true
        }

        for (let i = 1; i < grading.length; i++) {
            const prevItem = grading[i - 1]
            const currentItem = grading[i]
            if (mode) {
                // If edit mode
                if (currentItem.min > prevItem.max + EPSILON) {
                    // If any min value is updated, automatically update the previous grading max
                    if (isMin.value === true) {
                        prevItem.max = parseFloat(
                            (currentItem.min - EPSILON).toFixed(2)
                        )
                    }
                    // If any max value is updated, automatically update the current/next grading min
                    if (isMax.value === true) {
                        currentItem.min = parseFloat(
                            (prevItem.max + EPSILON).toFixed(2)
                        )
                    }
                } else if (currentItem.min <= prevItem.max) {
                    currentItem.max = parseFloat(
                        (prevItem.min - EPSILON).toFixed(2)
                    )
                }
                filledMissingRanges = true
            } else {
                // If add mode, update only the current item min as its a new entry based on the previous grading max
                if (currentItem.min > prevItem.max + EPSILON) {
                    currentItem.min = parseFloat(
                        (prevItem.max + EPSILON).toFixed(2)
                    )
                } else if (currentItem.min <= prevItem.max) {
                    currentItem.max = parseFloat(
                        (prevItem.min - EPSILON).toFixed(2)
                    )
                }
                filledMissingRanges = true
            }
        }

        if (grading[grading.length - 1].max !== 100) {
            grading[grading.length - 1].max = 100
            filledMissingRanges = true
        }

        return { grading, filledMissingRanges }
    }
    const translateStatuses = (testType) => {
        switch (testType) {
            case "Invited":
                return i18n.global.t("constants_text.invited")
            case "Completed":
                return i18n.global.t("constants_text.completed")
            case "In Progress":
                return i18n.global.t("constants_text.in_progress")
            case "Enrolled":
                return i18n.global.t("constants_text.enrolled")
            case "Disqualified":
                return i18n.global.t("constants_text.disqualified")
            case "Rejected":
                return i18n.global.t("constants_text.rejected")
            case "Invitation Expired":
                return i18n.global.t("constants_text.invitation_expired")
            case "Shortlisted":
                return i18n.global.t("candidate_stage_menu.shortlisted")
            case "Terminated":
                return i18n.global.t("candidate_stage_menu.terminated")
            case "Re Invited":
                return i18n.global.t("constants_text.reinvited")
            default:
                return ""
        }
    }
    const translateStages = (testType) => {
        switch (testType) {
            case "Not Yet Evaluated":
                return i18n.global.t("candidate_stage_menu.not_evaluated")
            case "Evaluated":
                return i18n.global.t("candidate_stage_menu.evaluated")
            case "Invited For Assessment":
                return i18n.global.t(
                    "candidate_stage_menu.invite_for_assessment"
                )
            case "Enrolled For Assessment":
                return i18n.global.t("candidate_stage_menu.enrolled_assessment")
            case "Assessment Completed":
                return i18n.global.t(
                    "candidate_stage_menu.assessment_completed"
                )
            case "Invited For Interview":
                return i18n.global.t(
                    "candidate_stage_menu.invite_for_interview"
                )
            case "Reference Verified":
                return i18n.global.t("candidate_stage_menu.ref_verified")
            case "Offer Sent":
                return i18n.global.t("candidate_stage_menu.offer_sent")
            case "Offer Declined":
                return i18n.global.t("candidate_stage_menu.offer_declined")
            case "Candidate Withdrew":
                return i18n.global.t("candidate_stage_menu.candidate_withdrew")
            case "Candidate Unresponsive":
                return i18n.global.t(
                    "candidate_stage_menu.candidate_uneresponsive"
                )
            case "Rejected":
                return i18n.global.t("candidate_stage_menu.rejected")
            case "Hired":
                return i18n.global.t("candidate_stage_menu.hired")
            case "Shortlisted":
                return i18n.global.t("candidate_stage_menu.shortlisted")
            default:
                return ""
        }
    }
    const processMediaInText = (text: any) => {
        const patterns = {
            image: /!\[image]\(/,
            audio: /```type:audio/,
            video: /```type:video/,
            iframe: /```type:iframe/,
            csv: /```type:csv/,
            txt: /```type:txt/,
            pdf: /```type:pdf/,
        }

        // Function to find the first match
        const findFirstMatch = (patterns, text) => {
            let firstMatch = { type: null, index: text.length }
            for (let type in patterns) {
                let match = text.search(patterns[type])
                if (match !== -1 && match < firstMatch.index) {
                    firstMatch = { type, index: match }
                }
            }
            return firstMatch.type ? firstMatch : null // Return null if no match
        }

        let firstMatch = findFirstMatch(patterns, text)

        if (firstMatch) {
            switch (firstMatch.type) {
                case "image":
                    text = text?.slice(0, firstMatch.index) + "[Image]"
                    break
                case "audio":
                    text = text?.slice(0, firstMatch.index) + "[Audio]"
                    break
                case "video":
                    text = text?.slice(0, firstMatch.index) + "[Video]"
                    break
                case "iframe":
                    text = text?.slice(0, firstMatch.index) + "[iFrame]"
                    break
                case "csv":
                    text = text?.slice(0, firstMatch.index) + "[CSV File]"
                    break
                case "txt":
                    text = text?.slice(0, firstMatch.index) + "[TXT File]"
                    break
                case "pdf":
                    text = text?.slice(0, firstMatch.index) + "[PDF File]"
            }
        }
        return checkTable(text)
    }

    const checkTable = (text: string) => {
        let lines = text.split("\n").filter((line) => line.trim() !== "") // Remove empty lines
        let firstNonEmptyLine = lines[0] // Get the first non-empty line
        if (/^\|.*\|$/.test(firstNonEmptyLine)) {
            return "Table"
        } else {
            return text
        }
    }

    const updatedTitle = (question: string) => {
        let text = question
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/\*\*/g, "")
            .replace(/##/g, "")
            .trim()
        let queList = text.split(/(?<=\.)\s+/)
        let queLength =
            queList.length > 1 && queList[0].length < 130
                ? queList[0].length + 1
                : 130
        if (text.length > queLength) {
            text = text.substring(0, queLength) + "..."
        }
        //Check if contains Image or Audio or Video
        text = processMediaInText(text)

        //Check if contains Table
        if (text.includes("|\n|")) {
            return "Table"
        } else {
            return text
        }
    }
    const getUsernameInitial = (username: string) => {
        return username ? username.charAt(0).toUpperCase() : ""
    }

    const removeMediaAndDocs = (text) => {
        return text
            .replace(/!\[.*?\]\(.*?\)/g, "") // Remove images: ![alt](url)
            .replace(
                /\[.*?\]\(.*?\.(mp4|webm|ogg|mp3|wav|flac|aac|m4a|mov|avi|wmv|mkv|flv|m4v)\)/gi,
                ""
            ) // Remove video/audio links
            .replace(
                /\[.*?\]\(.*?\.(pdf|docx?|xlsx?|pptx?|zip|rar|7z|tar|gz)\)/gi,
                ""
            ) // Remove document links
            .replace(/<audio[\s\S]*?<\/audio>/gi, "") // Remove <audio> HTML tags
            .replace(/<video[\s\S]*?<\/video>/gi, "") // Remove <video> HTML tags
    }
    const replaceEmptyLinetoBreak = (rawMarkdown: string): string => {
        return rawMarkdown.replace(
            /^(?!(#|\*|\-|\d+\.|\>|\`\`\`)).*\n\s*$/gm,
            (match) => {
                return match.trim() === "" ? "<br>" : match
            }
        )
    }

    const isPsychometric = (test: Record<string, any>): boolean => {
        return test.testLibraryTypeId === PSYCHOMETRIC_TEST_LIBRARY_TYPE_ID
    }

    return {
        isStaging,
        numberInRange,
        updateQueryParameter,
        isNumberWithMaxDigit,
        getUserCountByPlanName,
        formatTime,
        basicPlan,
        whiteLabeledPlan,
        convertSecToMin,
        initialCapitalize,
        initialLowerCase,
        snakeCaseToTitleCase,
        titleCaseToSnakeCase,
        convertedDate,
        downloadURI,
        formatDate,
        setSessionCookie,
        getSessionCookie,
        deleteSessionCookie,
        convertedDateTime,
        invitedDateTime,
        formatStatusDateTime,
        loadScript,
        getUTMCookies,
        showImagePreview,
        convertTimeToSeconds,
        toHMSTime,
        searchClient,
        randomStringGenerator,
        getCompanyName,
        getCurrencyCode,
        getShadeColor,
        setFavicon,
        markdownToPlainText,
        getPreviewDomain,
        getJwtExpiration,
        timeValueMin,
        convertMinutesToSeconds,
        isNumberAllowed,
        onlyNumbersAllowed,
        getMainDomain,
        getColorByBgColor,
        transparentise,
        typingTestDuration,
        checkResellerSupport,
        translateIndustry,
        roleNamesFormatting,
        hasNonEmptyOption,
        concatenateQuestionOptions,
        concatenateQuestionOptionsFIB,
        concatenateTestCases,
        getDurationValues,
        getTestTypeIcon,
        isValidNumericInput,
        assessmentDeadlineDateTime,
        getScoreBadge,
        isVideoAudioExpired,
        questionTypeTranslationMapList,
        orderedQuestionTypeMap,
        getDifficultyLevelIcon,
        checkLang,
        isPersonalEmail,
        filterLanguage,
        setCanonical,
        getQuestionCountFromLevelData,
        getTestLibrariesList,
        trimAndAddEllipsis,
        domainRestriction,
        generateSlug,
        formatToReadableDate,
        getMimeType,
        translateDifficultyLevel,
        formatPercentage,
        isTestlifyDomain,
        getTermsAndPrivacyUrl,
        isOptionRepeated,
        getDaysSinceCompletion,
        getAutoDeleteMessage,
        getCountryFromExt,
        formatDecimalNumber,
        checkValidation,
        fillMissingRanges,
        formatNumber,
        translateStatuses,
        translateStages,
        updatedTitle,
        processMediaInText,
        saveAdTrackingParams,
        getUsernameInitial,
        isGoogleFile,
        downloadGoogleFile,
        downloadFileDirectly,
        removeMediaAndDocs,
        replaceEmptyLinetoBreak,
        isPsychometric,
    }
}
